
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/plugins/i18n";
import { getIllustrationsPath, getLogoPath } from "@/core/helpers/assets";

export default defineComponent({
  components: {
  },
  setup() {
    
    onMounted(() => {
      setCurrentPageTitle(translate("BREADCRUMB.WELCOME"));
    });
    return {
      getIllustrationsPath
      , getLogoPath
    }
  },
});
